import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { EnvApiKeyDto, IEnvApiKey } from "@river/interfaces";
import { useParams } from "react-router-dom";
import {
  RiverDialog,
  RiverTextInput,
  useNotification,
  useValidation,
  IUseValidation,
  IValidationErrors,
  useSimpleDialog,
  RiverSpinner,
  PrimaryButton,
} from "@river/common-ui";
import { Box } from "@mui/material";
import { customerService } from "../../../services";
import { RiverDataGrid } from "../../river-data-grid";
import { Column } from "react-data-grid";
import CachedIcon from "@mui/icons-material/Cached";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { SubHeader } from "../../sub-header";
import { CustomerContext } from "../../customer";
import { EnvironmentContext } from "../../customer-environment";
import { PageHeader } from "../../page-header";
import sharedStyles from "../../shared-styles/shared-styles.module.scss";
import AddIcon from "@mui/icons-material/Add";
import styles from "./environment-api-keys.module.scss";
import clsx from "clsx";

export const EnvironmentApiKeys: React.FC = () => {
  const environmentHandle: string = useParams().environmentHandle!;

  const { customer } = useContext(CustomerContext)!;
  const { environment } = useContext(EnvironmentContext)!;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validation: IUseValidation = useValidation();

  const [environmentApiKeys, setEnvironmentApiKeys] = useState<EnvApiKeyDto[]>(
    []
  );
  const [environmentApiKeyDto, setEnvironmentApiKeyDto] =
    useState<EnvApiKeyDto>(new EnvApiKeyDto());
  const [environmentApiKeyDialogOpened, setEnvironmentApiKeyDialogOpened] =
    useState<boolean>(false);
  const selectedApiKeyRef = useRef<string>("");

  const notify = useNotification();

  useEffect(() => {
    fetchApiKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [validationErrors, setValidationErrors] = useState<IValidationErrors>({
    fields: {},
    list: [],
  });

  const resetValidationErrors = (): void =>
    setValidationErrors({
      fields: {},
      list: [],
    });

  const columns: Column<any>[] = [
    {
      key: "name",
      name: "Name",
      width: 130,
    },
    { key: "description", name: "Description", width: 130 },
    {
      key: "api_key",
      name: "API Keys",
      formatter: (row) => {
        return (
          <RiverTextInput
            id={"apiKey"}
            value={row.row.api_key}
            fullWidth
            className={styles.apiKeyTextBox}
            disabled
          />
        );
      },
    },
    {
      key: "actionIcons",
      name: "",
      formatter: (row) => {
        return (
          <div className={styles.icons}>
            <ContentCopyIcon
              className={styles.icon}
              onClick={() => copyToClipboard(row.row.api_key)}
              titleAccess={"Copy to Clipboard"}
            />
            <CachedIcon
              className={styles.icon}
              onClick={() => {
                console.log(row.row._id);
                selectedApiKeyRef.current = row.row._id;
                regenerateApiKeyConfirmationDialog.open();
              }}
              titleAccess={"Regenerate Key"}
            />
            <DeleteIcon
              className={styles.icon}
              onClick={() => {
                selectedApiKeyRef.current = row.row._id;
                deleteApiKeyConfirmationDialog.open();
              }}
              titleAccess={"Delete Key"}
            />
          </div>
        );
      },
      width: 120,
    },
  ];

  const fetchApiKeys = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const result: IEnvApiKey[] | null =
        await customerService.getEnvironmentApiKeys(
          customer!._id,
          environment!._id
        );
      setEnvironmentApiKeys(result as EnvApiKeyDto[]);
    } catch (message) {
      notify.error({ message });
    } finally {
      setIsLoading(false);
    }
  };

  const refresh = (): Promise<void> => fetchApiKeys();

  const refreshDto = (): void => {
    setEnvironmentApiKeyDto(new EnvApiKeyDto());
    resetValidationErrors();
  };

  const openApiKeyDialog = (): void => setEnvironmentApiKeyDialogOpened(true);
  const closeApiKeyDialog = (): void => {
    setEnvironmentApiKeyDialogOpened(false);
    refreshDto();
  };
  const validateApiKeysDto = async (
    environment: EnvApiKeyDto
  ): Promise<IValidationErrors> => {
    const errors = await validation.validateFields(environment);
    setValidationErrors(errors);
    return errors;
  };

  const createApiKey = async (): Promise<void> => {
    const errors: IValidationErrors =
      await validateApiKeysDto(environmentApiKeyDto);
    if (errors.list.length > 0) {
      return;
    }
    try {
      await customerService.generateEnvironmentApiKey(
        customer!._id,
        environment!._id,
        environmentApiKeyDto
      );
      closeApiKeyDialog();
      refresh();
      notify.success("Successfully create new API Key");
    } catch (message) {
      notify.error({ message });
    }
  };

  const regenerateApiKey = async (): Promise<void> => {
    try {
      await customerService.regenerateEnvironmentApiKey(
        customer!._id,
        environment!._id,
        selectedApiKeyRef.current
      );
      closeApiKeyDialog();
      refresh();
      notify.success("New API Key has been created");
    } catch (message) {
      notify.error({ message });
    }
  };

  const deleteApiKey = async (): Promise<void> => {
    try {
      await customerService.deleteEnvironmentApiKey(
        customer!._id,
        environment!._id,
        selectedApiKeyRef.current
      );
      closeApiKeyDialog();
      refresh();

      notify.success("API Key has been successfully deleted");
    } catch (message) {
      notify.error({ message });
    }
  };

  const copyToClipboard = async (apiKey: string): Promise<void> => {
    try {
      navigator.clipboard.writeText(apiKey);

      notify.success("API Key has been successfully copied to clipboard");
    } catch (message) {
      notify.error({ message });
    }
  };

  const onPropertyChange = async (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ): Promise<void> => {
    const newState: EnvApiKeyDto = {
      ...environmentApiKeyDto,
      [event.target.name!]: event.target.value,
    };
    Object.setPrototypeOf(
      newState,
      Object.getPrototypeOf(environmentApiKeyDto)
    );
    setEnvironmentApiKeyDto(newState);
    const newErrors: IValidationErrors = await validation.validateProperty(
      newState,
      event.target.name!,
      validationErrors
    );
    setValidationErrors(newErrors);
  };

  const renderNameField = (): ReactElement => (
    <RiverTextInput
      id="name"
      label="Name"
      value={environmentApiKeyDto.name}
      fullWidth
      className={sharedStyles.input}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["name"]}
    />
  );
  const renderDescriptionField = (): ReactElement => (
    <RiverTextInput
      id="description"
      label="Description"
      value={environmentApiKeyDto.description}
      fullWidth
      className={sharedStyles.input}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["description"]}
    ></RiverTextInput>
  );
  const renderAddApiKeyDialog = (): ReactElement => {
    return (
      <RiverDialog
        titleProps={{
          title: "Add New API Key",
          icon: AddIcon,
        }}
        open={environmentApiKeyDialogOpened}
        onClose={closeApiKeyDialog}
        actionButtonText="ADD"
        showActionsDivider={false}
        onSubmit={createApiKey}
      >
        {renderNameField()}
        {renderDescriptionField()}
      </RiverDialog>
    );
  };

  const deleteApiKeyConfirmationDialog = useSimpleDialog({
    title: "Delete API KEY",
    message: "Are you sure you want to delete this API Key?",
    confirmButtonText: "Delete",
    onConfirm: deleteApiKey,
  });

  const regenerateApiKeyConfirmationDialog = useSimpleDialog({
    title: "REGENERATE API KEY",
    message: "Are you sure you want to regenerate this API Key?",
    confirmButtonText: "Regenerate API Key",
    onConfirm: regenerateApiKey,
  });

  const renderAddApiKeyButton = (): ReactElement => (
    <PrimaryButton text={"Add API Key"} onClick={openApiKeyDialog} />
  );
  return (
    <>
      <PageHeader title={"Environments"} />
      <SubHeader subtitle={`${environmentHandle!} - API Keys`} />
      <Box className={sharedStyles.primaryButtons}>
        {renderAddApiKeyButton()}
      </Box>
      <Box className={sharedStyles.viewContent}>
        <RiverDataGrid
          columns={columns}
          rows={environmentApiKeys}
          className={clsx([sharedStyles.dataGrid, styles.apiKeysGrid])}
        />
      </Box>
      <RiverSpinner show={isLoading} />
      {renderAddApiKeyDialog()}
      {deleteApiKeyConfirmationDialog.render()}
      {regenerateApiKeyConfirmationDialog.render()}
    </>
  );
};
