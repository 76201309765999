import React, { ReactElement, useState } from "react";
import {
  RiverTextInput,
  RiverDialog,
  IValidationErrors,
  useValidation,
  useNotification,
  RiverFormSelect,
} from "@river/common-ui";
import AddIcon from "@mui/icons-material/Add";
import { ICustomer, LicenseDto } from "@river/interfaces";
import { customerService } from "../../services";
import { Constants } from "@river/constants";

interface IAddLicenseDialogProps {
  open: boolean;
  customer: ICustomer;
  onClose: (success: boolean) => void;
}

export const AddLicenseDialog: React.FC<IAddLicenseDialogProps> = (
  props
): ReactElement => {
  const [license, setLicense] = useState<LicenseDto>(new LicenseDto());
  const [validationErrors, setValidationErrors] = useState<IValidationErrors>({
    fields: {},
    list: [],
  });
  const notify = useNotification();
  const { validateFields, validateProperty } = useValidation();

  const resetValidationErrors = (): void =>
    setValidationErrors({
      fields: {},
      list: [],
    });

  const resetDialogState = (): void => {
    resetValidationErrors();
    setLicense(new LicenseDto());
  };

  const closeDialog = (success: boolean): void => {
    resetDialogState();
    props.onClose(success);
  };

  const onPropertyChange = async (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ): Promise<void> => {
    let newState: LicenseDto = {
      ...license,
      [event.target.name!]: event.target.value,
    };

    if (event.target.name! === "count") {
      newState = {
        ...license,
        [event.target.name!]: Number(event.target.value),
      };
    }
    Object.setPrototypeOf(newState, Object.getPrototypeOf(license));
    setLicense(newState);
    const newErrors: IValidationErrors = await validateProperty(
      newState,
      event.target.name!,
      validationErrors
    );
    setValidationErrors(newErrors);
  };

  const validateLicense = async (
    License: LicenseDto
  ): Promise<IValidationErrors> => {
    const errors = await validateFields(License);
    setValidationErrors(errors);
    return errors;
  };

  const addLicense = async (): Promise<void> => {
    const errors: IValidationErrors = await validateLicense(license);
    if (errors.list.length > 0) {
      return;
    }

    try {
      await customerService.createCustomerLicense(props.customer._id!, license);
      const success = true;
      closeDialog(success);
    } catch (message) {
      notify.error({ message });
    }
  };

  const renderlicenseField = (): ReactElement => (
    <RiverFormSelect
      items={[
        {
          text: Constants.license_type.admin,
          value: Constants.license_type.admin,
        },
        {
          text: Constants.license_type.craftsman,
          value: Constants.license_type.craftsman,
        },
        {
          text: Constants.license_type.scheduler,
          value: Constants.license_type.scheduler,
        },
        {
          text: Constants.license_type.supervisor,
          value: Constants.license_type.supervisor,
        },
      ]}
      value={license.license}
      onChangeEvent={(event) => {
        onPropertyChange(
          event as React.ChangeEvent<{ name?: string; value: unknown }>
        );
      }}
      id={"license"}
      label={"License"}
      fullWidth
      validationErrors={validationErrors?.fields["license"]}
    />
  );

  const renderDescriptionField = (): ReactElement => (
    <RiverTextInput
      id={"description"}
      label={"Description..."}
      value={license.description || ""}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["description"]}
      inputProps={{
        multiline: true,
        minRows: 5,
      }}
      fullWidth
    />
  );
  const renderCountField = (): ReactElement => (
    <RiverTextInput
      id={"count"}
      label={"Count"}
      value={license.count || 0}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["count"]}
      fullWidth
      inputProps={{
        type: "number",
      }}
    />
  );

  return (
    <RiverDialog
      titleProps={{
        title: "Add a New License",
        icon: AddIcon,
      }}
      open={props.open}
      onClose={() => {
        const success = false;
        closeDialog(success);
      }}
      actionButtonText="Add"
      showActionsDivider={false}
      onSubmit={addLicense}
    >
      {renderlicenseField()}
      {renderDescriptionField()}
      {renderCountField()}
    </RiverDialog>
  );
};
