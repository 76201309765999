import React, { ReactElement, useState, useContext } from "react";
import {
  PrimaryButton,
  RiverDialogButton,
  RiverFormSelect,
  RiverTextInput,
  useNotification,
} from "@river/common-ui";
import { EnvironmentDto, IProperty } from "@river/interfaces";
import { Config } from "@river/config";
import { Constants } from "@river/constants";
import { customerService } from "../../../services";
import { Box, IconButton } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import { SubHeader } from "../../sub-header";
import { CustomerContext } from "../../customer";
import { EnvironmentContext } from "../../customer-environment";
import { PageHeader } from "../../page-header";
import sharedStyles from "../../shared-styles/shared-styles.module.scss";
import styles from "./environment-details.module.scss";
import clsx from "clsx";

export const EnvironmentDetails: React.FC = () => {
  const notify = useNotification();
  const { customer } = useContext(CustomerContext)!;
  const { environment, setEnvironment } = useContext(EnvironmentContext)!;

  const [environmentDto, setEnvironmentDto] = useState<EnvironmentDto>(
    environment as EnvironmentDto
  );

  const onPropertyChange = async (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ): Promise<void> => {
    const newState: EnvironmentDto = {
      ...environmentDto,
      [event.target.name!]: event.target.value,
    };

    Object.setPrototypeOf(newState, Object.getPrototypeOf(environmentDto));
    setEnvironmentDto(newState);
  };

  const onParamPropertyChange = async (
    index: number,
    event: React.ChangeEvent<{ name: string; value: unknown }>
  ): Promise<void> => {
    const options: IProperty[] = environmentDto.options!;
    const newOptions = [...options];
    newOptions[index][event.target.name] = event.target.value;
    const newState: EnvironmentDto = {
      ...environmentDto,
      options: newOptions,
    };
    Object.setPrototypeOf(newState, Object.getPrototypeOf(environmentDto));
    setEnvironmentDto(newState);
  };

  const updateEnvironmentDetails = async (): Promise<void> => {
    const environmentId: string = environment!._id;
    try {
      await customerService
        .updateCustomerEnvironment(customer!._id, environmentId, environmentDto)
        .then((data) => {
          // It returns  object that has incremented version
          setEnvironmentDto(data);
          setEnvironment(data);
        });
      notify.success(`Successfully saved Environment Details Configuration `);
    } catch (message) {
      notify.error({ message });
    }
  };

  const renderCustomerHandleField = (): ReactElement => (
    <RiverTextInput
      name="customer_handle"
      id="customer_handle"
      label="Customer Handle"
      value={customer!.handle}
      disabled
      fullWidth
      className={sharedStyles.input}
    />
  );
  const renderAuthenticationField = (): ReactElement => (
    <RiverFormSelect
      items={[
        {
          text: Constants.authentication_type.basic,
          value: Constants.authentication_type.basic,
        },
        {
          text: Constants.authentication_type.saml2,
          value: Constants.authentication_type.saml2,
        },
        {
          text: Constants.authentication_type.oauth2,
          value: Constants.authentication_type.oauth2,
        },
      ]}
      value={environmentDto.authentication}
      onChangeEvent={(event) => {
        onPropertyChange(
          event as React.ChangeEvent<{ name?: string; value: unknown }>
        );
      }}
      id={"authentication"}
      label={"Authentication"}
      className={sharedStyles.input}
      fullWidth
    />
  );
  const renderEnvironmentUrlField = (): ReactElement => (
    <div
      onClick={() => {
        window.open(
          `https://${environmentDto.handle}-${customer!.handle}.${
            Config.web.REACT_APP_ADAPTER_WEB_DOMAIN
          }`
        );
      }}
      className={sharedStyles.input}
    >
      <RiverTextInput
        name="customer_environment_url"
        id="customer_environment_url"
        label="Environment URL"
        value={`${environmentDto.handle}-${customer!.handle}.${
          Config.web.REACT_APP_ADAPTER_WEB_DOMAIN
        }`}
        fullWidth
        disabled
        className={sharedStyles.link}
      />
    </div>
  );

  const removeParam = (index: number): void => {
    const params: IProperty[] = environmentDto.options || [];
    if (params.length) {
      const newParams: IProperty[] = [...params];
      newParams.splice(index, 1);
      const newDto: EnvironmentDto = {
        ...environmentDto,
        options: newParams,
      };
      Object.setPrototypeOf(newDto, Object.getPrototypeOf(environmentDto));
      setEnvironmentDto(newDto);
    }
  };

  const addParam = (): void => {
    const params: IProperty[] = environmentDto.options || [];
    const newParams: IProperty[] = [...params];
    newParams.push({ name: "", value: "" });
    const newDto: EnvironmentDto = {
      ...environmentDto,
      options: newParams,
    };
    Object.setPrototypeOf(newDto, Object.getPrototypeOf(environmentDto));
    setEnvironmentDto(newDto);
  };

  const renderAddParamButton = (): ReactElement => (
    <PrimaryButton
      text={"Add connection parameter"}
      onClick={addParam}
      startIcon={<AddCircleIcon />}
      classes={{ root: styles.addParamButton }}
      variant="text"
    />
  );

  const renderConnectionParameters = (): ReactElement => (
    <Box className={styles.addParamsSection}>
      {Number(environmentDto.options?.length) > 0 && (
        <span className={styles.title}>Connection Parameters</span>
      )}
      <Box className={styles.params}>
        {environmentDto.options?.map((option, index) => (
          <Box key={index} className={styles.param}>
            <RiverTextInput
              label={"Name"}
              id={"name"}
              onChangeEvent={(event) => onParamPropertyChange(index, event)}
              className={clsx([styles.field])}
              value={option.name}
            />
            <RiverTextInput
              label={"Value"}
              id={"value"}
              onChangeEvent={(event) => onParamPropertyChange(index, event)}
              className={clsx([styles.field, styles.value])}
              value={option.value}
            />
            <IconButton
              classes={{ root: styles.removeParamButton }}
              onClick={() => {
                removeParam(index);
              }}
              size="large"
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
        ))}
      </Box>
      {renderAddParamButton()}
    </Box>
  );

  return (
    <>
      {!!environmentDto && (
        <>
          <PageHeader title={"Environments"} />
          <SubHeader subtitle={`${environmentDto.handle} - Details`} />
          <Box className={sharedStyles.viewContent}>
            <div className={sharedStyles.form}>
              {renderCustomerHandleField()}
              {renderAuthenticationField()}
              {renderEnvironmentUrlField()}
              {renderConnectionParameters()}
              <div className={sharedStyles.buttonGroup}>
                <RiverDialogButton
                  text="Save"
                  variant="contained"
                  className={sharedStyles.button}
                  onClick={updateEnvironmentDetails}
                />
              </div>
            </div>
          </Box>
        </>
      )}
    </>
  );
};
