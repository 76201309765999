import React, { ReactElement, useEffect, useState } from "react";
import { CustomerDto, ICluster } from "@river/interfaces";
import { Divider } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  RiverTextInput,
  RiverFormSelect,
  IRiverSimpleSelectItem,
  RiverDialog,
  ImageUpload,
  IValidationErrors,
  useValidation,
  IUseValidation,
  useNotification,
} from "@river/common-ui";
import { Constants } from "@river/constants";
import { clusterService, customerService } from "../../services";

interface IAddCustomerDialogProps {
  open: boolean;
  onClose: (success: boolean) => void;
}

export const AddCustomerDialog: React.FC<IAddCustomerDialogProps> = (
  props
): ReactElement => {
  const validation: IUseValidation = useValidation();
  const [customer, setCustomer] = useState<CustomerDto>(new CustomerDto());
  const [validationErrors, setValidationErrors] = useState<IValidationErrors>({
    fields: {},
    list: [],
  });
  const [clusters, setClusters] = useState<ICluster[]>([]);
  const notify = useNotification();

  useEffect(() => {
    if (props.open) {
      getClusters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  const getClusters = () => {
    clusterService
      .getClusters()
      .then((result) => {
        setClusters(result);
      })
      .catch((message) => {
        notify.error({ message });
      });
  };

  const resetValidationErrors = (): void =>
    setValidationErrors({
      fields: {},
      list: [],
    });

  const resetDialogState = (): void => {
    resetValidationErrors();
    setCustomer(new CustomerDto());
  };

  const closeDialog = (success: boolean): void => {
    resetDialogState();
    props.onClose(success);
  };

  const onPropertyChange = async (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ): Promise<void> => {
    const newState: CustomerDto = {
      ...customer,
      [event.target.name!]: event.target.value,
    };
    Object.setPrototypeOf(newState, Object.getPrototypeOf(customer));
    setCustomer(newState);
    const newErrors: IValidationErrors = await validation.validateProperty(
      newState,
      event.target.name!,
      validationErrors
    );
    setValidationErrors(newErrors);
  };

  const validateCustomer = async (
    customer: CustomerDto
  ): Promise<IValidationErrors> => {
    const errors = await validation.validateFields(customer);
    setValidationErrors(errors);
    return errors;
  };

  const addCustomer = async (): Promise<void> => {
    const errors: IValidationErrors = await validateCustomer(customer);
    if (errors.list.length > 0) {
      console.log(`Validation errors:`, errors.list);
      return;
    }

    customerService
      .createCustomer(customer)
      .then(() => {
        const success = true;
        closeDialog(success);
      })
      .catch((message) => {
        notify.error({ message });
      });
  };

  const renderCustomerNameField = (): ReactElement => (
    <RiverTextInput
      id={"description"}
      label={"Company Name"}
      value={customer.description}
      onChangeEvent={onPropertyChange}
      validationErrors={validationErrors?.fields["description"]}
      fullWidth
    />
  );

  const renderCustomerHandleField = (): ReactElement => (
    <RiverTextInput
      id={"handle"}
      label={"Handle"}
      value={customer.handle}
      onChangeEvent={onPropertyChange}
      validationErrors={validationErrors?.fields["handle"]}
      fullWidth
    />
  );

  const renderAdapterField = (): ReactElement => {
    const entries: IRiverSimpleSelectItem[] = Object.entries(
      Constants.adapter_type
    ).map((item) => {
      return { value: item[1], text: item[1] };
    });

    return (
      <RiverFormSelect
        label={"Adapter Type"}
        id={"adapter_type"}
        items={entries}
        onChangeEvent={(event) => {
          onPropertyChange(
            event as React.ChangeEvent<{ name?: string; value: unknown }>
          );
        }}
        value={customer.adapter_type}
        validationErrors={validationErrors?.fields["adapter_type"]}
        fullWidth
      />
    );
  };

  const renderClusterField = (): ReactElement => {
    const entries: IRiverSimpleSelectItem[] = clusters.map((cluster) => {
      return { value: cluster._id!, text: cluster.description! };
    });

    return (
      <RiverFormSelect
        label={"Cluster"}
        id={"cluster_id"}
        items={entries}
        onChangeEvent={(event) => {
          onPropertyChange(
            event as React.ChangeEvent<{ name?: string; value: unknown }>
          );
        }}
        value={customer.cluster_id}
        validationErrors={validationErrors?.fields["cluster_id"]}
        fullWidth
      />
    );
  };

  return (
    <RiverDialog
      titleProps={{
        title: "Add a Company",
        icon: AddIcon,
      }}
      open={props.open}
      onClose={() => {
        const success = false;
        closeDialog(success);
      }}
      actionButtonText="Add"
      onSubmit={addCustomer}
    >
      {renderCustomerNameField()}
      {renderCustomerHandleField()}
      {renderAdapterField()}
      {renderClusterField()}
      <ImageUpload uploadUrl={""} />
      <Divider />
    </RiverDialog>
  );
};
