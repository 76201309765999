import React, { ReactElement, useEffect, useState } from "react";
import {
  RiverTextInput,
  RiverPasswordInput,
  RiverDialog,
  IValidationErrors,
  useValidation,
  IUseValidation,
  useNotification,
} from "@river/common-ui";
import AddIcon from "@mui/icons-material/Add";
import { ICustomer, PlatformUserDto } from "@river/interfaces";
import { customerService } from "../../services";

interface IAddUserDialogProps {
  customer: ICustomer;
  open: boolean;
  onClose: (success: boolean) => void;
}

export const AddUserDialog: React.FC<IAddUserDialogProps> = (
  props
): ReactElement => {
  const validation: IUseValidation = useValidation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [user, setUser] = useState<PlatformUserDto>(new PlatformUserDto());
  const [validationErrors, setValidationErrors] = useState<IValidationErrors>({
    fields: {},
    list: [],
  });
  const notify = useNotification();

  useEffect(() => {
    setIsOpen(props.open);
  }, [props.open]);

  const resetValidationErrors = (): void =>
    setValidationErrors({
      fields: {},
      list: [],
    });

  const resetDialogState = (): void => {
    resetValidationErrors();
    setUser(new PlatformUserDto());
  };

  const closeDialog = (success: boolean): void => {
    resetDialogState();
    props.onClose(success);
  };

  const validateUser = async (
    user: PlatformUserDto
  ): Promise<IValidationErrors> => {
    const errors = await validation.validateFields(user);
    setValidationErrors(errors);
    return errors;
  };

  const addUser = async (): Promise<void> => {
    const errors: IValidationErrors = await validateUser(user);
    if (errors.list.length > 0) {
      return;
    }
    try {
      await customerService.createCustomerUser(props.customer._id!, user);
      const success = true;
      closeDialog(success);
    } catch (message) {
      notify.error({ message });
    }
  };

  const onPropertyChange = async (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ): Promise<void> => {
    const newState: PlatformUserDto = {
      ...user,
      [event.target.name!]: event.target.value,
    };
    Object.setPrototypeOf(newState, Object.getPrototypeOf(user));
    setUser(newState);
    const newErrors: IValidationErrors = await validation.validateProperty(
      newState,
      event.target.name!,
      validationErrors
    );
    setValidationErrors(newErrors);
  };

  const renderDisplayNameField = (): ReactElement => (
    <RiverTextInput
      id={"display_name"}
      label={"Display Name"}
      value={user.display_name}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["display_name"]}
      fullWidth
    />
  );

  const renderEmailField = (): ReactElement => (
    <RiverTextInput
      id={"email"}
      label={"Email"}
      value={user.email}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["email"]}
      fullWidth
    />
  );

  const renderPasswordField = (): ReactElement => (
    <RiverPasswordInput
      id={"password"}
      value={user.password}
      label={"Password"}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["password"]}
      fullWidth
    />
  );

  return (
    <RiverDialog
      titleProps={{
        title: "Add a user",
        icon: AddIcon,
      }}
      open={isOpen}
      onClose={() => {
        const success = false;
        closeDialog(success);
      }}
      actionButtonText="Add"
      onSubmit={addUser}
      showActionsDivider={false}
    >
      {renderDisplayNameField()}
      {renderEmailField()}
      {/*Must wrap in form to prevent browser from pre-populating*/}
      <form>{renderPasswordField()}</form>
    </RiverDialog>
  );
};
