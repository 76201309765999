import React, { ReactElement, useEffect, useMemo, useState } from "react";
import {
  RiverDialog,
  RiverDialogButton,
  useNotification,
} from "@river/common-ui";
import {
  ICustomer,
  ModuleActionSummaryDto,
  ModuleSummaryDto,
} from "@river/interfaces";
import { customerService } from "../../../services";
import { RiverDataGrid } from "../../river-data-grid";
import { Column } from "react-data-grid";
import { RiverCellExpanderFormatter } from "../../river-data-grid";
import { Constants } from "@river/constants";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { RiverCheckboxFormatter } from "@river/common-ui";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import styles from "./assign-module-and-actions-dialog.module.scss";
import clsx from "clsx";

interface IAssignModuleAndActionsDialogProps {
  open: boolean;
  customer: ICustomer;
  onClose: (success: boolean) => void;
  selectedLicense: any;
}

export const AssignModuleAndActionsDialog: React.FC<
  IAssignModuleAndActionsDialogProps
> = (props): ReactElement => {
  const [customerModules, setCustomerModules] = useState<any[]>([]);

  const notify = useNotification();

  const closeDialog = (success: boolean): void => {
    props.onClose(success);
  };

  const getCustomerModules = () => {
    if (!props.open) {
      return;
    }
    const fnGetCustomerModules = async () => {
      try {
        const result = await customerService.getCustomerModules(
          props.customer._id!,
          props.selectedLicense._id!
        );
        setCustomerModules(result as ModuleSummaryDto[]);
      } catch (message) {
        notify.error({ message });
      }
    };
    fnGetCustomerModules();
  };

  useEffect(() => {
    getCustomerModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedLicense, props.open]);

  const toggleSubRow = (row: any) => {
    const rowIndex = customerModules.findIndex((r) => {
      return r._id === row._id;
    });
    const { actions } = row;
    if (!actions) return;

    const newRows = [...customerModules];
    newRows[rowIndex] = { ...row, isExpanded: !row.isExpanded };
    if (!row.isExpanded) {
      newRows.splice(rowIndex + 1, 0, ...actions);
    } else {
      newRows.splice(rowIndex + 1, actions.length);
    }
    setCustomerModules(newRows);
  };

  const updateModuleStatus = async (row: any, license: string) => {
    const editedActions: any[] = row.actions.map((item: any) => {
      return { ...item, status: { code: license } };
    });

    // Select all actions of modules or deselect if module isn't selected
    const editedCustomerModules = customerModules.map((item) => {
      if (item.module === row.module) {
        if (item.action) {
          return { ...item, status: { code: license } };
        } else if (!item.action) {
          return {
            ...item,
            status: { code: license },
            actions: editedActions,
          };
        }
      }
      return item;
    });

    try {
      await customerService.changeCustomerModuleStatus(
        props.customer._id!,
        props.selectedLicense._id,
        {
          ...row,
          status: { code: license },
        } as ModuleSummaryDto
      );
      editedActions.forEach(async (item) => {
        try {
          await customerService.changeCustomerModuleActionStatus(
            props.customer._id!,
            props.selectedLicense._id,
            row.module,
            {
              ...item,
              status: { code: license },
            } as ModuleActionSummaryDto
          );
        } catch (message) {
          notify.error({ message });
        }
      });

      setCustomerModules(editedCustomerModules);
    } catch (message) {
      notify.error({ message });
    }
  };

  const updateActionStatus = async (row: any, license: string) => {
    const editedCustomerModules = customerModules.map((item) => {
      //update actions of modules visually
      if (row.module === item.module && item.actions) {
        return {
          ...item,
          actions: item.actions.map((x: any) => {
            if (x._id === row._id) {
              return { ...x, status: { code: license } };
            } else {
              return x;
            }
          }),
        };
      }
      if (row === item) {
        return { ...item, status: { code: license } };
      } else {
        return item;
      }
    });

    try {
      await customerService.changeCustomerModuleActionStatus(
        props.customer._id!,
        props.selectedLicense._id,
        row.module,
        {
          ...row,
          status: { code: license },
        } as ModuleActionSummaryDto
      );
      setCustomerModules(editedCustomerModules);
    } catch (message) {
      notify.error({ message });
    }
  };

  const onCheckboxChange = async (row: any) => {
    let license: string = Constants.module_status.licensed;
    if (row.status.code === Constants.module_status.licensed) {
      license = Constants.module_status.none;
    }
    // Checks if its Module
    if (row.actions) {
      updateModuleStatus(row, license);
    } else {
      updateActionStatus(row, license);
    }
  };

  const RenderModulesAndActionsTreeView = (): ReactElement => {
    const columns: Column<any>[] = useMemo(() => {
      return [
        {
          key: "module/actions",
          name: "Module",
          width: 350,
          formatter({ row, isCellSelected }) {
            if (row.actions) {
              return (
                <div
                  className={clsx([
                    styles.dropDownModule,
                    styles.clickableDropDownModule,
                  ])}
                >
                  <RiverCellExpanderFormatter
                    isCellSelected={isCellSelected}
                    expanded={row.isExpanded}
                    onCellExpand={() => {
                      toggleSubRow(row);
                    }}
                  />
                  <p className={styles.dropDownModuleText}>{row.module}</p>
                </div>
              );
            }
            return (
              <>
                <div
                  className={clsx([
                    styles.dropDownModule,
                    styles.subDirectoryIcon,
                  ])}
                >
                  <SubdirectoryArrowRightIcon />
                  <p className={styles.dropDownModuleText}>{row.action}</p>
                </div>
              </>
            );
          },
        },
        { key: "description", name: "Description" },
        {
          key: "status",
          name: "Licensed?",
          width: 80,
          formatter: ({ row }) => {
            return (
              <RiverCheckboxFormatter
                checked={row.status.code === Constants.module_status.licensed}
                onChange={() => onCheckboxChange(row)}
                id="status"
              />
            );
          },
        },
      ];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerModules]);

    return (
      <RiverDataGrid
        columns={columns}
        rows={customerModules}
        className={styles.dataGrid}
      />
    );
  };

  return (
    <RiverDialog
      titleProps={{
        title: "Assign Modules/Actions",
        icon: AssignmentTurnedInIcon,
      }}
      open={props.open}
      onClose={() => {
        const success = false;
        closeDialog(success);
      }}
      classes={{ paper: styles.paper, content: styles.content }}
      showActionsDivider={false}
      onSubmit={() => {}}
      actionsContent={
        <RiverDialogButton
          text="Close"
          onClick={() => {
            const success = false;
            closeDialog(success);
          }}
        />
      }
    >
      {RenderModulesAndActionsTreeView()}
    </RiverDialog>
  );
};
