import {
  IsNotEmpty,
  IsArray,
  IsOptional,
  ValidateNested,
} from "class-validator";
import { Type } from "class-transformer";
import { IAdapterJobPriorityScoreAction } from "./adapter-job-priority-score-action.interface";
import { QueryDto } from "../query";

export class AdapterJobPriorityScoreActionDto
  implements IAdapterJobPriorityScoreAction
{
  @IsNotEmpty()
  entity_name: string;

  // Other ERP specific attributes
  [s: string]: any;

  @IsOptional()
  @IsArray()
  entity_ids?: string[];

  @IsOptional()
  @ValidateNested()
  @Type(() => QueryDto)
  query?: QueryDto;

  @IsOptional()
  pipeline?: string;
}
