import {
  IsArray,
  IsNotEmpty,
  IsOptional,
  ValidateNested,
} from "class-validator";
import { Type } from "class-transformer";
import { IAdapterMaterialShortageAction } from "./adapter-material-shortage-action.interface";
import { QueryDto } from "../query";

export class AdapterMaterialShortageActionDto
  implements IAdapterMaterialShortageAction
{
  @IsOptional()
  @IsArray()
  entity_ids?: string[];

  @IsOptional()
  @ValidateNested()
  @Type(() => QueryDto)
  query?: QueryDto;

  @IsOptional()
  pipeline?: string;
}
