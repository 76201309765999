import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { FormHelperText } from "@mui/material";
import { useFormField } from "../../../hooks";
import styles from "./river-form-field-error.module.scss";
import clsx from "clsx";
export const RiverFormFieldError = (props) => {
    const { helperText } = useFormField(props);
    const renderContent = () => (_jsx("span", { className: styles.content, children: helperText }));
    return (_jsx(_Fragment, { children: props.contentOnly ? (renderContent()) : (_jsx(FormHelperText, { id: `${props.id}-helper-text`, classes: { root: clsx([props.className]) }, children: renderContent() })) }));
};
