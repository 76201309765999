import {
  IsNotEmpty,
  IsArray,
  IsOptional,
  ValidateNested,
} from "class-validator";
import { Type } from "class-transformer";
import { IAdapterFolderAction } from "./adapter-folder-action.interface";
import { QueryDto } from "../query";

export class AdapterFolderActionDto implements IAdapterFolderAction {
  @IsNotEmpty()
  folder_id: string; // In case of SAP => 'workorder' | 'operation' | 'assignment'

  @IsNotEmpty()
  entity_name: string; // In case of SAP => 'workorder' | 'operation' | 'assignment'

  @IsOptional()
  @IsArray()
  entity_ids?: string[];

  @IsOptional()
  @ValidateNested()
  @Type(() => QueryDto)
  query?: QueryDto;
}
