import {
  IsDate,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  ValidateIf,
} from "class-validator";

import { IAdapterAssignmentChangeAction } from "./adapter-assignment-change-action.interface";

export class AdapterAssignmentChangeActionDto
  implements IAdapterAssignmentChangeAction
{
  @IsNotEmpty()
  folder_id: string;

  @IsDate()
  start_date: Date;

  @IsOptional()
  @IsNumber()
  hours?: number;

  @IsNotEmpty()
  @ValidateIf((obj, value) => value !== null)
  availability_header_id: string | null;

  @IsOptional()
  auto_post?: boolean;
}
