import { IsDate, IsNotEmpty, IsNumber, IsOptional } from "class-validator";
import { IAdapterAssignmentAction } from "./adapter-assignment-action.interface";

export class AdapterAssignmentActionDto implements IAdapterAssignmentAction {
  @IsNotEmpty()
  folder_id: string;

  @IsDate()
  start_date: Date;

  @IsOptional()
  @IsNumber()
  hours?: number;

  @IsOptional()
  availability_header_id?: string;

  @IsNotEmpty()
  entity_ids: string[]; //operation id

  @IsOptional()
  auto_post?: boolean;
}
