import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { RiverDialog, RiverDialogButton, RiverDialogActionButton, } from "../../components";
import { useTranslation } from "../use-translation";
import styles from "./use-simple-dialog.module.scss";
const DEFAULT_DECLINE_BUTTON_TEXT = "common.button:cancel";
const DEFAULT_CONFIRM_BUTTON_TEXT = "common.button:ok";
export function useSimpleDialog(props) {
    const { t } = useTranslation();
    const [title, setTitle] = useState("");
    const [icon, setIcon] = useState();
    const [message, setMessage] = useState("");
    const [content, setContent] = useState(_jsx(_Fragment, {}));
    const [declineButtonText, setDeclineButtonText] = useState("");
    const [confirmButtonText, setConfirmButtonText] = useState("");
    const [opened, setOpened] = useState(false);
    const [onDecline, setOnDecline] = useState(() => () => { });
    const [onConfirm, setOnConfirm] = useState(() => () => { });
    const defaultDeclineButtonText = t(DEFAULT_DECLINE_BUTTON_TEXT);
    const defaultConfirmButtonText = t(DEFAULT_CONFIRM_BUTTON_TEXT);
    const render = () => (_jsx(_Fragment, { children: _jsx(RiverDialog, { titleProps: {
                title,
                icon,
            }, showActionsDivider: false, open: opened, onClose: () => setOpened(false), actionsContent: _jsxs(_Fragment, { children: [_jsx(RiverDialogButton, { className: styles.actionButton, onClick: () => {
                            onDecline();
                        }, text: declineButtonText }), _jsx(RiverDialogActionButton, { className: styles.actionButton, onClick: () => {
                            onConfirm();
                        }, text: confirmButtonText })] }), classes: Object.assign({ content: styles.content }, props === null || props === void 0 ? void 0 : props.classes), children: message ? _jsx("div", { children: message }) : content }) }));
    return {
        open: (openProps) => {
            setTitle((openProps === null || openProps === void 0 ? void 0 : openProps.title) || (props === null || props === void 0 ? void 0 : props.title));
            setIcon(() => (openProps === null || openProps === void 0 ? void 0 : openProps.icon) || (props === null || props === void 0 ? void 0 : props.icon));
            setMessage((openProps === null || openProps === void 0 ? void 0 : openProps.message) || (props === null || props === void 0 ? void 0 : props.message));
            setContent((openProps === null || openProps === void 0 ? void 0 : openProps.content) || (props === null || props === void 0 ? void 0 : props.content));
            setDeclineButtonText((openProps === null || openProps === void 0 ? void 0 : openProps.declineButtonText) ||
                (props === null || props === void 0 ? void 0 : props.declineButtonText) ||
                defaultDeclineButtonText);
            setConfirmButtonText((openProps === null || openProps === void 0 ? void 0 : openProps.confirmButtonText) ||
                (props === null || props === void 0 ? void 0 : props.confirmButtonText) ||
                defaultConfirmButtonText);
            setOnDecline(() => {
                if (openProps === null || openProps === void 0 ? void 0 : openProps.onDecline) {
                    return () => {
                        var _a;
                        setOpened(false);
                        (_a = openProps === null || openProps === void 0 ? void 0 : openProps.onDecline) === null || _a === void 0 ? void 0 : _a.call(openProps);
                    };
                }
                else if (props === null || props === void 0 ? void 0 : props.onDecline) {
                    return () => {
                        var _a;
                        setOpened(false);
                        (_a = props === null || props === void 0 ? void 0 : props.onDecline) === null || _a === void 0 ? void 0 : _a.call(props);
                    };
                }
                else {
                    return () => {
                        setOpened(false);
                    };
                }
            });
            setOnConfirm(() => {
                if (openProps === null || openProps === void 0 ? void 0 : openProps.onConfirm) {
                    return () => {
                        var _a;
                        setOpened(false);
                        (_a = openProps === null || openProps === void 0 ? void 0 : openProps.onConfirm) === null || _a === void 0 ? void 0 : _a.call(openProps);
                    };
                }
                else if (props === null || props === void 0 ? void 0 : props.onConfirm) {
                    return () => {
                        var _a;
                        setOpened(false);
                        (_a = props === null || props === void 0 ? void 0 : props.onConfirm) === null || _a === void 0 ? void 0 : _a.call(props);
                    };
                }
                else {
                    return () => {
                        setOpened(false);
                    };
                }
            });
            setOpened(true);
        },
        close: () => setOpened(false),
        render,
    };
}
