import React, { ReactElement, useState } from "react";
import {
  RiverTextInput,
  RiverDialog,
  IValidationErrors,
  useValidation,
  IUseValidation,
  useNotification,
  PrimaryButton,
} from "@river/common-ui";
import AddIcon from "@mui/icons-material/Add";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { ICustomer, EnvironmentDto, IProperty } from "@river/interfaces";
import { customerService } from "../../services";
import { Constants } from "@river/constants";
import styles from "./add-environment-dialog.module.scss";
import clsx from "clsx";

interface AddEnvironmentDialogProps {
  open: boolean;
  customer: ICustomer;
  onClose: (success: boolean) => void;
}

export const AddEnvironmentDialog: React.FC<AddEnvironmentDialogProps> = (
  props
): ReactElement => {
  const createEnvironmentDto = (): EnvironmentDto => {
    const dto = new EnvironmentDto();

    switch (props.customer.adapter_type) {
      case Constants.adapter_type.sap:
        dto.options = [
          {
            name: "sap-username",
            value: "",
          },
        ];
        break;

      case Constants.adapter_type.oracle_cloud:
        dto.options = [
          {
            name: "oracle-base-url",
            value: "",
          },
          {
            name: "oracle-version",
            value: "11.13.18.05",
          },
          {
            name: "oracle-username",
            value: "",
          },
        ];
        break;

      case Constants.adapter_type.oracle_ebs:
        dto.options = [
          {
            name: "ebs-username",
            value: "",
          },
        ];
        break;

      case Constants.adapter_type.jde:
        dto.options = [
          {
            name: "jde-base-url",
            value: "",
          },
          {
            name: "jde-username",
            value: "",
          },
          {
            name: "jde-environment",
            value: "",
          },
          {
            name: "jde-role",
            value: "*ALL",
          },
          {
            name: "jde-webgui-url",
            value: "",
          },
          {
            name: "jde_workcenter_utilization_per_branch",
            value: "1",
          },
        ];
        break;
    }

    return dto;
  };

  const validation: IUseValidation = useValidation();
  const [environment, setEnvironment] = useState<EnvironmentDto>(
    createEnvironmentDto()
  );
  const [validationErrors, setValidationErrors] = useState<IValidationErrors>({
    fields: {},
    list: [],
  });
  const notify = useNotification();

  const resetValidationErrors = (): void =>
    setValidationErrors({
      fields: {},
      list: [],
    });

  const resetDialogState = (): void => {
    resetValidationErrors();
    setEnvironment(new EnvironmentDto());
  };

  const closeDialog = (success: boolean): void => {
    resetDialogState();
    props.onClose(success);
  };

  const onPropertyChange = async (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ): Promise<void> => {
    const newState: EnvironmentDto = {
      ...environment,
      [event.target.name!]: event.target.value,
    };
    Object.setPrototypeOf(newState, Object.getPrototypeOf(environment));
    setEnvironment(newState);
    const newErrors: IValidationErrors = await validation.validateProperty(
      newState,
      event.target.name!,
      validationErrors
    );
    setValidationErrors(newErrors);
  };

  const validateEnvironment = async (
    environment: EnvironmentDto
  ): Promise<IValidationErrors> => {
    const errors = await validation.validateFields(environment);
    setValidationErrors(errors);
    return errors;
  };

  const onParamPropertyChange = async (
    index: number,
    event: React.ChangeEvent<{ name: string; value: unknown }>
  ): Promise<void> => {
    const options: IProperty[] = environment.options!;
    const newOptions = [...options];
    newOptions[index][event.target.name] = event.target.value;
    const newState: EnvironmentDto = {
      ...environment,
      options: newOptions,
    };
    Object.setPrototypeOf(newState, Object.getPrototypeOf(environment));
    setEnvironment(newState);
  };

  const addEnvironment = async (): Promise<void> => {
    const errors: IValidationErrors = await validateEnvironment(environment);
    if (errors.list.length > 0) {
      return;
    }

    try {
      await customerService.createCustomerEnvironment(
        props.customer._id!,
        environment
      );
      const success = true;
      closeDialog(success);
    } catch (message) {
      notify.error({ message });
    }
  };

  const renderHandleField = (): ReactElement => (
    <RiverTextInput
      id={"handle"}
      label={"Handle"}
      value={environment.handle || ""}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["handle"]}
      fullWidth
    />
  );

  const renderDescriptionField = (): ReactElement => (
    <RiverTextInput
      id={"description"}
      label={"Description..."}
      value={environment.description || ""}
      onChangeEvent={(event) => onPropertyChange(event)}
      validationErrors={validationErrors?.fields["description"]}
      inputProps={{
        multiline: true,
        minRows: 5,
      }}
      fullWidth
    />
  );

  const removeParam = (index: number): void => {
    const params: IProperty[] = environment.options || [];
    if (params.length) {
      const newParams: IProperty[] = [...params];
      newParams.splice(index, 1);
      const newDto: EnvironmentDto = {
        ...environment,
        options: newParams,
      };
      Object.setPrototypeOf(newDto, Object.getPrototypeOf(environment));
      setEnvironment(newDto);
    }
  };

  const addParam = (): void => {
    const params: IProperty[] = environment.options || [];
    const newParams: IProperty[] = [...params];
    newParams.push({ name: "", value: "" });
    const newDto: EnvironmentDto = {
      ...environment,
      options: newParams,
    };
    Object.setPrototypeOf(newDto, Object.getPrototypeOf(environment));
    setEnvironment(newDto);
  };

  const renderAddParamButton = (): ReactElement => (
    <PrimaryButton
      text={"Add connection parameter"}
      onClick={addParam}
      classes={{ root: styles.addParamButton }}
      variant="text"
      startIcon={<AddCircleIcon />}
    />
  );

  const renderConnectionParameters = (): ReactElement => (
    <Box className={styles.addParamsSection}>
      {Number(environment.options?.length) > 0 && (
        <span className={styles.title}>Connection Parameters</span>
      )}
      <Box className={styles.params}>
        {environment.options?.map((option, index) => (
          <Box key={index} className={styles.param}>
            <RiverTextInput
              label={"Name"}
              id={"name"}
              onChangeEvent={(event) => onParamPropertyChange(index, event)}
              className={clsx([styles.field, styles.name])}
              value={option.name}
            />
            <RiverTextInput
              label={"Value"}
              id={"value"}
              onChangeEvent={(event) => onParamPropertyChange(index, event)}
              className={clsx([styles.field, styles.value])}
              value={option.value}
            />
            <IconButton
              classes={{ root: styles.removeParamButton }}
              onClick={() => {
                removeParam(index);
              }}
              size="large"
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
        ))}
      </Box>
      {renderAddParamButton()}
    </Box>
  );

  return (
    <RiverDialog
      titleProps={{
        title: "Add a New Environment",
        icon: AddIcon,
      }}
      open={props.open}
      onClose={() => {
        const success = false;
        closeDialog(success);
      }}
      classes={{ paper: styles.paper, content: styles.content }}
      actionButtonText="Add"
      showActionsDivider={false}
      onSubmit={addEnvironment}
    >
      {renderHandleField()}
      {renderConnectionParameters()}
      {renderDescriptionField()}
    </RiverDialog>
  );
};
