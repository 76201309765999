import {
  IsArray,
  IsNotEmpty,
  IsOptional,
  ValidateNested,
  IsDate,
} from "class-validator";
import { Type } from "class-transformer";

import { QueryDto } from "../query";
import { IAdapterModifyErpDateActionAsync } from "./adapter-modify-erp-date-action-async.interface";

export class AdapterModifyErpDateActionAsyncDto
  implements IAdapterModifyErpDateActionAsync
{
  @IsNotEmpty()
  @IsDate()
  start_date: Date;

  @IsNotEmpty()
  entity_name: string;

  @IsOptional()
  @IsArray()
  entity_ids?: string[]; //selected entities

  @IsOptional()
  @ValidateNested()
  @Type(() => QueryDto)
  query?: QueryDto;
}
